<template>
  <div class="pied">
    <!-- <div class="w-100 home-footer mt-4">
      <div class="inner">
        <div class="links px-2 pt-2 d-flex justify-content-around">

          <div class="">
            <AppLogo
              :blacknwhite="true"
              :height="100"
            />
          </div>
          <div class="">

            <div class="grid-gap-10">
              <div><router-link to="/">
                A propos
              </router-link></div>
              <div><router-link to="/contacts">
                Nous contacter
              </router-link></div>
            </div>
          </div>
        </div>
        <div class="px-2 my-1 mt-3">
          <p class="d-flex justify-content-center">
            <span>
              COPYRIGHT © {{ new Date().getFullYear() }}
              <b-link
                class="ml-25"
                href="/"
                target="_blank"
              >{{
                appName
              }}</b-link>
            </span>
          </p>
        </div>
      </div>
      <ColorBandGouvBj />
    </div> -->

    <div class="w-100 home-footer mt-4">
      <!-- <div class="inner"> -->
      <!-- <div class="links px-2 pt-2 d-flex justify-content-around"> -->

      <div class="row px-2 pt-2">
        <div class="col-lg-2 col-md-1" />
        <div class="col-lg-4 col-md-5">
          <div class="">
            <AppLogo
              :blacknwhite="true"
              :height="100"
            />
          </div>
        </div>
        <div class="col-lg-3 col-md-3" />
        <div class="col-lg-3 col-md-3">
          <div class="mt-1">
            <div class="grid-gap-10">
              <div>
                <router-link to="/">
                  <!-- A propos -->
                </router-link>
              </div>
              <div>
                <router-link to="/contacts">
                  Nous contacter
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <div class="px-2 my-1 mt-2">
        <p class="d-flex justify-content-center">
          <span>
            COPYRIGHT © {{ new Date().getFullYear() }}
            <b-link
              class="ml-25"
              href="/"
              target="_blank"
            >{{
              appName
            }}</b-link>
          </span>
        </p>
      </div>
      <!-- </div> -->
      <ColorBandGouvBj />
    </div>
  </div>
</template>

<script>
import { BImg, BLink } from 'bootstrap-vue'
import AppLogo from '@/components/AppLogo.vue'
import ColorBandGouvBj from '@/components/ColorBandGouvBj.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
    BLink,
    AppLogo,
    ColorBandGouvBj,
  },
  directives: {},
  props: {},
  computed: {},
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>
<style lang="scss" scoped>
.pied {
  overflow-x: hidden;
}
.home-footer {
  background: #2a2828;
      padding-top: 25px;

  a {
    color: white;
    transition: all 0.1s ease-in 0.05s;
    &:hover {
      font-weight: bold;
    }
  }
  .inner {
    .links {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      // margin-top: 20px;
      // background: white;
      @media (max-width: 767.98px) {
        grid-template-columns: 1fr;
      }
    }
  }
}
.grid-gap-10 {
  display: grid;
  gap: 10px;
}
</style>
